import React from 'react'
import toast from 'react-hot-toast'

export default function Home() {
          return (
                    <div className='flex flex-col gap-2 justify-center items-center h-screen bg-gradient-to-br from-pink to-blue'>
                              <h1 className='text-xl md:text-3xl font-semibold select-none text-white'>This is a <span onClick={() => toast.success('Hello Buddy!')} className='text-red-600 hover:text-white cursor-pointer duration-500'>Demo</span> Page</h1>
                              <div className='absolute bottom-5 px-3'>
                                        <p className='text-white text-xs md:text-sm uppercase text-center'>
                                                  Made with ❤️ by <span onClick={() => window.open("https://toufiqhasankiron.com", "_blank", "noopener,noreferrer")} className='text-red-600 font-semibold duration-500 hover:text-white cursor-pointer'>Toufiq Hasan Kiron</span>
                                        </p>
                              </div>
                    </div>
          )
}
